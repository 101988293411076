import Vue from 'vue'
Vue.use(VueRouter)
import { baseConsoleRoutes } from '~bac/router'
export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',                                              //首页
      name: 'index',
      component: resolve => require(['~exp/views/index'], resolve),
    },
    {
      path: '/moreWonderful',                                              //首页
      name: 'moreWonderful',
      component: resolve => require(['~exp/views/moreWonderful'], resolve),
    },
    {   //线下
      path: '/offLine',
      name: 'offLine',
      component: resolve => require(['~exp/views/exhibition/offLine'], resolve),
    },
    {   //线上
      path: '/onLine',
      name: 'onLine',
      component: resolve => require(['~exp/views/exhibition/onLine'], resolve),
    },
    {
      path: '/exhibitorServe',                                              //展商服务
      name: 'exhibitorServe',
      component: resolve => require(['~exp/views/exhibitorServe/index'], resolve),
    },
    {
      path: '/exhibitionList',                                              //展商名录
      name: 'exhibitionList',
      component: resolve => require(['~exp/views/exhibitionList/index'], resolve),
    },
    {
      path: '/audienceService',                                              //观众服务
      name: 'audienceService',
      component: resolve => require(['~exp/views/audienceService/index'], resolve),
    },
    {
      path: '/register',//报名页面
      name: 'register',
      component: resolve => require(['~exp/views/register/index'], resolve)
    },
    {
      path: '/registerTwo',//报名页面第二步
      name: 'registerTwo',
      component: resolve => require(['~exp/views/register/registertwo'], resolve)
    },
    {
      path: '/registerSuccess',//报名成功页面
      name: 'registerSuccess',
      component: resolve => require(['~exp/views/register/success'], resolve)
    },
    {
      path: '/sponsorChance',//赞助机会
      name: 'sponsorChance',
      component: resolve => require(['~exp/views/sponsorChance/index'], resolve)
    },
    {
      path: '/exhibitionSummary',//展会概括
      name: 'exhibitionSummary',
      component: resolve => require(['~exp/views/aboutExp/exhibitionSummary'], resolve)
    },
    {
      path: '/Offlinetour',//线下导览
      name: 'Offlinetour',
      component: resolve => require(['~exp/views/aboutExp/Offlinetour'], resolve)
    },
    {
      path: '/VisitExhibition',//观展
      name: 'VisitExhibition',
      component: resolve => require(['~exp/views/VisitExhibition/index'], resolve)
    },
    {
      path: '/exhibitionagenda',  //展会议程
      name: 'exhibitionagenda',
      component: resolve => require(['~exp/views/exhibitionagenda/index'], resolve)
    },

    {
      path: '/sign',  //登录
      name: 'sign',
      component: resolve => require(['~exp/views/users/sign'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/signUp',  //注册
      name: 'signUp',
      component: resolve => require(['~exp/views/users/signUp'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/forgetPassword',  //忘记密码
      name: 'forgetPassword',
      component: resolve => require(['~exp/views/users/forgetPassword'], resolve),
    },
    {
      path: '/resetPassword',  //重置密码
      name: 'resetPassword',
      component: resolve => require(['~exp/views/users/resetPassword'], resolve),
    },
    {
      path: '/wxLogin',  //微信登录过渡页
      name: 'wxLogin',
      component: resolve => require(['~exp/views/users/skipBing/wxLogin'], resolve),
      meta: {
        layout: 'empty'
      }
    },
    {
      path: '/skipBing',  //微信登录过渡页
      name: 'skipBing',
      component: resolve => require(['~exp/views/users/skipBing/index'], resolve),
      meta: {
        layout: 'empty'
      }
    },
    {
      path: '/exhibitionagenda',  //展会议程
      name: 'exhibitionagenda',
      component: resolve => require(['~exp/views/exhibitionagenda/index'], resolve)
    },
    {
      path: '/newsMessageDetails',  // 新闻资讯详情
      name: "newsMessageDetails",
      component: resolve => require(['~exp/views/newsMessage/details'], resolve)
    },
    {
      path: '/list',  // 抗疫贡献企业 抗疫英雄 抗疫英雄
      name: 'list',
      component: resolve => require(['~exp/views/rankingList/list'], resolve)
    },
    {
      path: '/overallRankingList',  //  总排行榜
      name: 'overallRankingList',
      component: resolve => require(['~exp/views/rankingList/overallRankingList'], resolve)
    },
    {
      path: '/overallRankingDetail',  //  排行榜详情:
      name: 'overallRankingDetail',
      component: resolve => require(['~exp/views/rankingList/overallRankingDetail'], resolve)
    },
    {
      path: '/activity',  //投票活动
      name: 'activity',
      component: resolve => require(['~exp/views/rankingList/activity'], resolve)
    },
    {
      path: '/negotiates',  //直播大厅
      name: 'negotiates',
      component: resolve => require(['~exp/views/negotiates/index'], resolve)
    },
    {
      path: '/userCard',  //直通海外
      name: 'userCard',
      component: resolve => require(['~exp/views/directOverseas/userCard'], resolve)
    }, {

      path: '/test',
      name: 'test',
      meta: {
        layout: 'empty'
      },
      component: resolve => require(['~exp/views/test'], resolve)
    },
    {
      path: '/boothList',  //展台列表
      name: 'boothList',
      component: resolve => require(['~exp/views/booth/list'], resolve)
    },
    {
      path: '/pavilion',  //展台列表
      name: 'pavilion',
      component: resolve => require(['~exp/views/booth/pavilion'], resolve)
    },
    {
      path: '/boothDetail',  //展台列表
      name: 'boothDetail',
      component: resolve => require(['~exp/views/booth/detail'], resolve)
    },
    {
      path: '/miniBooth',  //迷你展台
      name: 'miniBooth',
      meta: {
        layout: 'empty'
      },
      component: resolve => require(['~exp/views/booth/miniBooth/index'], resolve)
    },
    {
      path: '/chatLogin',  //迷你展台
      name: 'chatLogin',
      meta: {
        layout: 'empty'
      },
      component: resolve => require(['~exp/views/booth/miniBooth/chatLogin'], resolve)
    },
    {
      path: '/helpCenter',
      name: 'helpCenter',
      component: resolve => require(['~exp/views/helpCenter'], resolve)
    },
    {
      path: '/handleInitialParams',
      name: 'handleInitialParams',
      meta: {
        layout: 'empty'
      },
      component: resolve => require(['~exp/views/users/initPage'], resolve)
    },
    {
      path: '/boothMobile',
      name: 'boothMobile',
      component: resolve => require(['~exp/views/boothMobile'], resolve),
      meta: {
        layout: 'empty'
      }
    },
    {
      path: '/bookinged',
      name: 'bookinged',
      component: resolve => require(['~exp/views/boothMobile/bookinged.vue'], resolve),
      meta: {
        layout: 'empty'
      }
    },
  ].concat(baseConsoleRoutes).concat([{
    path: "**",
    redirect:"/"
  }]),
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
